<template>
    <div class="right-cont-panel">
        <h4 class="right-title">雇主信息</h4>
        <div class="engineer-panel hirer-panel">
            <div
                class="msg-cont"
                @click="goDetail(pageData._employer.user_id)"
            >
                <span class="avatar">
                    <img
                        :src="pageData._employer.avatar?(websiteConfigs.sourceUrl + pageData._employer.avatar):$img.search_pic"
                        alt
                    />
                </span>
                <div class="msg-txt">
                    <h4 class="name">
                        <span>{{pageData._employer.geek_id}}</span>
                        <i
                            class="tag blue"
                            v-if="pageData._employer.attestation_type && pageData._employer.attestation_type == 1"
                        >个人入驻</i>
                        <i
                            class="tag orange"
                            v-if="pageData._employer.attestation_type && pageData._employer.attestation_type == 2"
                        >公司入驻</i>
                        <i
                            class="tag green"
                            v-if="pageData._employer.attestation_type && pageData._employer.attestation_type == 3"
                        >工作室入驻</i>
                    </h4>
                    <p class="add"></p>
                </div>
            </div>
            <ul class="msg-status">
                <li>
                    <span>注册时间：</span>
                    <strong>{{pageData._employer.ctime?pageData._employer.ctime:'-'}}</strong>
                </li>
                <li>
                    <span>共计支付：</span>
                    <strong>￥{{pageData._employer.pay_all?pageData._employer.pay_all:''}}</strong>
                </li>
                <li>
                    <span>信用评分：</span>
                    <el-rate
                        v-model="pageData._employer.credence"
                        disabled
                        show-score
                        text-color="#f59b15"
                        score-template="{value}"
                    ></el-rate>
                </li>
                <li>
                    <span>发布项目数量：</span>
                    <strong>{{pageData._employer.publish_all?pageData._employer.publish_all:''}}个</strong>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: "task",
    components: {

    },
    props: {
        pageData: {}
    },
    data() {
        return {
            value: 3.7,
        };
    },
    methods: {
        goDetail(val) {
            this.$router.push('/firm/employer?geek_id=' + val);
        }
    }
};
</script>



