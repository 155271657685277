<template>
  <div class="count-down time" v-html="leftTimeString"></div>
</template>

<script>
export default {
  name: "Counter" /* 倒计时 */,
  data() {
    return {
      over: false, // 是否已结束
      leftTimeString: "",
      interval: 1000 // 默认时间间隔
    };
  },
  props: ["endTime"], //endTime: "2019/09/17 19:34:00", // 截止时间 时间格式: 2019/01/01 00:00:00, 用 - 连接会有兼容性问题(IE: ???), ememem... ios 也会有, 当然也可以用时间戳
  created() {
    this.init();
  },
  methods: {
    // 初始化
    init() {
      const interval = this.interval || 1000;
      // 间隔 interval 执行一次
      const timer = setInterval(() => {
        if (this.over) {
          clearInterval(timer);
          return;
        }
        this.start();
      }, interval);
    },

    // 开始计时
    start() {
      let leftTimes = this.countDown();
      this.leftTimeString = `<span class="num">${
        leftTimes[0]
      }</span><span>天</span><span class="num">${
        leftTimes[1]
      }</span><span>时</span><span class="num">${
        leftTimes[2]
      }</span><span>分</span><span class="num">${
        leftTimes[3]
      }</span><span>秒</span>`;
    },

    // 倒计时
    countDown() {
      // 如果截止时间为空
      if (!this.endTime) {
        // console.log("截止时间为空");
        this.over = true;
        this.$emit("endCount"); // 提交倒计时结束事件
        return ["00", "00", "00", "00"];
      }

      // 截止时间
      const endTime = new Date(this.endTime * 1000);

      // 当前时间
      const nowTime = new Date();

      // 剩余时间 秒
      let leftTime = parseInt((endTime.getTime() - nowTime) / 1000);

      // 如果截止时间已经过期
      if (leftTime <= 0) {
        this.over = true;
        this.$emit("endCount"); // 提交倒计时结束事件
        return ["00", "00", "00", "00"];
      }

      // 取整
      let D = this.addPrefixZero(parseInt(leftTime / (24 * 60 * 60)));
      let H = this.addPrefixZero(parseInt((leftTime / (60 * 60)) % 24));
      let M = this.addPrefixZero(parseInt((leftTime / 60) % 60));
      let S = this.addPrefixZero(parseInt(leftTime % 60));

      return [D, H, M, S];
    },

    // 补前缀 0
    addPrefixZero(number) {
      if (number >= 10) return number + "";
      return `0${number}`;
    }
  }
};
</script>

<style lang="less">
.time {
  float: left;
  margin-left: 10px;

  span {
    display: inline-block;
    width: 30px;
    height: 24px;
    line-height: 24px;
    text-align: center;

    border-radius: 4px;
    background: none;
    color: #587ddc;
  }

  .num {
    background: #587ddc;
    color: #fff;
  }
}
</style>



