<template>
    <div class="task-detail-panel">
        <h3 class="t-detail-title">报名人</h3>
        <ul class="sign-up-infos">
            <li
                class="item"
                v-for="(item,index) in signMemberList.lists"
                :key="index"
            >
                <div class="avatar">
                    <el-image
                        style="width: 100%; height: 100%"
                        :src=" item._geek.avatar?util.reImg(item._geek.avatar):$img.search_pic"
                        fit="cover"
                    ></el-image>

                </div>
                <div class="info-cont">
                    <div class="info-cont-hd">
                        <div class="top">
                            <time class="date">{{item._geek.utime}}</time>
                            <div class="title-wrap">
                                <h4 class="title">{{item._geek.name}}</h4>
                                <span
                                    v-if="item._geek.attestation_type=='0'"
                                    class="attest"
                                    :class="{'orange': item._geek.attestation_type=='0', 'blue': item._geek.attestation_type=='1',
                                    'green': item._geek.attestation_type=='2'}"
                                >未入驻</span><!-- 此处需要程序返回认证类型 -->
                                <span
                                    v-if="item._geek.attestation_type=='1'"
                                    class="attest"
                                    :class="{'orange': item._geek.attestation_type=='0', 'blue': item._geek.attestation_type=='1',
                                    'green': item._geek.attestation_type=='2'}"
                                >个人极客</span>
                                <span
                                    v-if="item._geek.attestation_type=='2'"
                                    class="attest"
                                    :class="{'orange': item._geek.attestation_type=='0', 'blue': item._geek.attestation_type=='1',
                                    'green': item._geek.attestation_type=='2'}"
                                >工作室极客</span>
                                <span
                                    v-if="item._geek.attestation_type=='3'"
                                    class="attest"
                                    :class="{'orange': item._geek.attestation_type=='0', 'blue': item._geek.attestation_type=='1',
                                    'green': item._geek.attestation_type=='2'}"
                                >单位极客</span>
                            </div>
                        </div>
                        <div class="bottom">
                            <div class="bottom-left">
                                <span class="area">{{item._geek.areas}}</span>
                                <!-- 得分需要后台调整value的格式为数字类型  item._geek.credence -->
                                <el-rate
                                    :value="item._geek.credence"
                                    disabled
                                    show-score
                                    text-color="#ff9900"
                                    :score-template="item._geek.credence"
                                ></el-rate>
                            </div>
                            <div class="bottom-right">
                                <span>完成项目：{{item._geek.task_total}}次</span>
                                <span>获得酬劳：{{item._geek.reward}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="info-cont-bd">
                        <div class="illustrate">
                            <p v-html="item.info.replace(/\n/g,'<br/>')"></p>
                            <!-- <a href="javascript:void(0)">显示全部</a> -->
                        </div>
                    </div>
                    <div class="info-cont-ftr">
                        <div class="price">
                            <p class="money">
                                报价
                                <span v-if="parseInt(item.task_day) > 0">￥{{item.quoted_price}} </span>
                                <span v-if="parseInt(item.task_day) == 0">￥{{item.quoted_price}} </span>元
                                <i v-if="parseInt(item.task_day) > 0">工期 </i>
                                <span v-if="parseInt(item.task_day) > 0">{{item.task_day}}</span> <i v-if="parseInt(item.task_day) > 0">天</i>
                            </p>
                        </div>
                        <div class="annex">
                            <span>附件：</span><!-- 需要程序返回数据----------------------------- -->
                            <ul class="annex-txt">
                                <li
                                    v-for="(it1,i1) of item.annex"
                                    :key="i1"
                                >
                                    <span>{{it1.name}}</span>
                                    <em>{{item.first_time}}</em>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="operate-ment">
                        <a
                            href="javascript:void(0)"
                            class="oper-lik"
                            @click="send(item._geek.user_id)"
                        >联系TA</a>
                        <a
                            href="javascript:void(0)"
                            class="oper-lik"
                            @click="catHonor(item._geek.user_id)"
                        >查看资质证书</a>
                        <a
                            href="javascript:void(0)"
                            class="oper-lik"
                            @click="catUse(item._geek.user_id)"
                        >查看对方资料</a>
                        <a
                            href="javascript:void(0)"
                            class="rt oper-lik blue-menu"
                            v-if="item.has_invite == 1"
                            @click="intvite(item.task_id,item.geeks_id)"
                        >发送邀请</a>
                        <a
                            href="javascript:void(0)"
                            class="rt oper-lik blue-menu"
                            v-if="item.has_invite == 2"
                        >不可邀请</a>
                        <a
                            href="javascript:void(0)"
                            class="rt oper-lik blue-menu"
                            v-if="item.has_invite == 3"
                        >已经邀请</a>
                    </div>
                </div>
            </li>
        </ul>
        <a
            href="javascript:void(0)"
            class="more-btn"
            v-if="page*limit < total"
            @click="changeLimit"
        >
            显示更多
            <i class="jk-icon-zhankaigengduo"></i>
        </a>
    </div>
</template>
<script>
export default {
    name: "task",
    components: {},
    props: {
        pageData: {}
    },
    data() {
        return {
            page: 1,
            limit: 10,
            total: 0,
            signMemberList: []
        };
    },
    methods: {
        getSignUpDatas() {
            // 获取报名人列表
            this.post(
                "/task/index/enrolls",
                {
                    task_id: this.$route.query.task_id,
                    page: this.page,
                    limit: this.limit
                },
                res => {
                    if (
                        200 == res.code &&
                        res.hasOwnProperty("data")
                    ) {
                        this.signMemberList = res.data;
                    }
                }
            );
        },
        changeLimit() {
            this.limit += 10;
            this.getSignUpDatas();
        },
        intvite(task_id, geeks_id) {
            this.$router.push(
                "/user/todos/sendInvit/?tid=" + task_id + "&gid=" + geeks_id
            );
        },
        send(mid) {
            this.$router.push("/user/message/sendmsg/?user_id=" + mid);
        },
        catHonor(mid) {
            this.$router.push("/firm/honor?geek_id=" + mid);
        },
        catUse(uk) {
            //存储当前值
            sessionStorage.setItem('currentPageUrl', document.location.pathname + document.location.search);
            this.$router.push('/firm/index?view=1&uk=' + uk);
        }
    },
    created() {
        this.getSignUpDatas(); // 获取报名列表
    }
};
</script>



