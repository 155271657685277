<template>
    <div class="right-cont-panel">
        <h4 class="right-title">相似项目</h4>
        <ul class="similar-task">
            <li class="item" v-for="(item,index) in task" :key="index">
                <h4 class="name">{{item.name}}</h4>
                <p class="date">距离报名截止：<span>{{item.date}}</span> 天</p>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "task",
    components: {
        
    },
    data() {
        return {
            task:[
                {
                    name:'曲靖地区工程勘察项目工程勘察项目务察任',
                    date:3
                },
                {
                    name:'曲靖地区工程勘察项目工程勘察项目务察任',
                    date:3
                },
                {
                    name:'曲靖地区工程勘察项目工程勘察项目务察任',
                    date:3
                }
            ]
        };
    },
};
</script>



