<template>
    <div @click="hiddenGkListEvent">
        <top-nav></top-nav>
        <header-guide @winscroll="winScroll"></header-guide>
        <bread-crumb></bread-crumb>

        <main class="taskdetail-panel">
            <div
                :class="fixed_class"
                v-if="isView"
            >
                <span class="tip">您现在正在浏览项目详情，可以点击右侧的按钮返回继续其他操作。</span>
                <!-- <span class="tip">您现在正在浏览项目，当前页面为预览页面，如对项目还有修改，可以点击右侧的按钮返回继续编辑，如无需修改可以直接点击发布。</span> -->
                <span
                    class="back-btn"
                    v-if="task.uk==userInfo.info.uk && (task.status == -1 || task.status == 1)"
                    @click="goEdit(task.tk)"
                    style="right:101px;"
                >编辑项目</span>
                <span
                    class="back-btn"
                    @click="goBack"
                    style="right:0px;width:100px"
                >返回</span>
                <!-- 
                <span class="publish-btn" @click="goPulish(task.id)">立即发布</span>-->
            </div>

            <div class="t-top">
                <div class="left">
                    <div class="name task-name">{{task.task_name}}</div>
                    <div class="time-box">
                        <div
                            class="type"
                            v-if="task.task_type == 1"
                            v-text="task.task_type_value"
                        ></div>
                        <div
                            class="type bx"
                            v-if="task.task_type == 2"
                            v-text="task.task_type_value"
                        ></div>
                        <div
                            class="type rx"
                            v-if="task.task_type == 3"
                            v-text="task.task_type_value"
                        ></div>

                        <div
                            class="time-text"
                            v-if="task.status == 3"
                        >距离报名结束还剩</div>

                        <div
                            class="time"
                            v-if="task.status == 3"
                        >
                            <counter
                                :end-time="task.end_time"
                                @endCount="endCount"
                            ></counter>
                        </div>
                    </div>
                    <div class="prop-item">
                        <span class="prop-item-name">雇主：</span>
                        <span class="username">{{task.employer_name}}</span>
                        <span>于 {{task.approve_time}} 发布该项目</span>
                    </div>

                    <div class="prop-item">
                        <span class="prop-item-name">项目编号：</span>
                        <span>{{task.sn}}</span>
                    </div>

                    <div class="prop-item">
                        <span class="prop-item-name">服务分类：</span>
                        <span
                            class="tag_link"
                            @click="goTaskList(task.speciality_type_value)"
                        >{{task.speciality_type_value}}</span>
                    </div>

                    <div class="price">
                        <span class="price-name">项目酬金</span>
                        <span class="price-value">
                            <i v-if="task.task_type != 3">{{task.remuneration}}元</i>
                            <i v-if="task.task_type == 3">总酬金：{{task.remuneration}}元 &nbsp;&nbsp;工期：{{task.task_day}}天 &nbsp;&nbsp;每天酬金：{{task.remuneration/task.task_day}}元</i>
                        </span>
                    </div>

                    <div style="position:absolute;right:40px;top:34px;">
                        <span
                            style="font-size:20px;color:#587ddc"
                            v-if="task.status == 3"
                        >报名中</span>
                        <span
                            style="font-size:20px;color:#587ddc"
                            v-if="task.status == 4"
                        >评选中</span>
                        <span
                            style="font-size:20px;color:#587ddc"
                            v-if="task.status == 5"
                        >待签署</span>
                        <span
                            style="font-size:20px;color:#587ddc"
                            v-if="task.status == 6"
                        >待缴纳</span>
                        <span
                            style="font-size:20px;color:#587ddc"
                            v-if="task.status == 7"
                        >工作中</span>
                        <span
                            style="font-size:20px;color:#587ddc"
                            v-if="task.status == 8"
                        >已完成</span>
                        <span
                            style="font-size:20px;color:#587ddc"
                            v-if="task.status == 9"
                        >调解中</span>
                        <span
                            style="font-size:20px;color:#587ddc"
                            v-if="task.status == 10"
                        >已结束</span>
                    </div>
                </div>

                <div
                    class="right"
                    v-if="task.employer"
                >
                    <div class="title">雇主信息</div>

                    <div class="info">
                        <div
                            class="avatar"
                            v-if="task.employer"
                        >
                            <div :style="'background: url('+ util.reImg(task.employer.avatar) +') no-repeat center;background-size:100% auto; '"></div>
                        </div>
                        <div
                            class="employer-info"
                            v-if="task.employer"
                        >
                            <span
                                class="user-name"
                                :title="task.employer_name"
                            >{{task.employer_name}}</span>
                            <span class="atte">{{task.employer.attestation_type_value}}</span>
                        </div>
                        <span class="area">{{task.employer.area_value}}</span>
                    </div>

                    <div class="user-item">
                        <span class="name">注册时间：</span>
                        <span>{{task.employer?task.employer.ctime:''}}</span>
                    </div>

                    <div class="user-item">
                        <span
                            class="name"
                            style="float:left"
                        >信用评分：</span>
                        <el-rate
                            style="float:left;padding-top:5px;"
                            :value="task.employer.credence*1"
                            disabled
                            show-score
                            text-color="#f59b15"
                        ></el-rate>
                    </div>

                    <div class="user-item">
                        <span class="name">发布项目：</span>
                        <span class="font">{{task.employer.task_total}}</span>
                        <span class="dw">个</span>
                    </div>

                    <div
                        class="user-item btn"
                        v-if="userInfo.isLogin == true && (userInfo.info.uk == task.uk)"
                    >
                        <el-button
                            @click="catUse(task.uk)"
                            style="width:80px;"
                        >我的详情</el-button>
                    </div>

                    <div
                        class="user-item btn"
                        v-else
                    >
                        <el-button
                            @click="catUse(task.uk)"
                            style="width:80px;"
                        >雇主详情</el-button>
                        <el-button
                            @click="send(task.uk)"
                            style="width:80px;"
                        >发消息</el-button>
                        <el-button
                            @click="keep"
                            v-if="is_subscribe==0"
                            style="width:80px;"
                        >收藏项目</el-button>
                        <el-button
                            @click="keep()"
                            v-if="is_subscribe==1"
                            style="background-color:#999999"
                        >已收藏</el-button>
                    </div>
                </div>
            </div>

            <section class="container task-detls-wraper">
                <div class="taskdetail-hd">
                    <!-- 项目进度 -->
                    <div class="progress-panel">
                        <div class="stype1-wraper">
                            <el-steps
                                :active="nodeActive"
                                align-center
                            >
                                <el-step
                                    v-for="(item,index) in nodeList"
                                    :class="'step'+(index+1)"
                                    :key="index"
                                    icon="el-icon-arrow-right"
                                    :title="item.title"
                                    :description="item.time.toString()"
                                ></el-step>
                            </el-steps>
                        </div>

                        <!-- 如果要走以下分支，active数字从2开始，不走的时候默认为0 -->
                        <!-- <div class="stype2-wraper">
                            <el-steps :active="1" class="type2-steps" align-center>
                                <el-step icon="el-icon-arrow-right" class="step-nth1"></el-step>
                                <el-step
                                    class="step-nth2"
                                    icon="el-icon-arrow-right"
                                    title="线下交易"
                                    description="2019-09-22"
                                ></el-step>
                                <el-step
                                    class="step-nth3"
                                    icon="el-icon-arrow-right"
                                    title="项目完结"
                                    description="2019-09-22"
                                ></el-step>
                            </el-steps>
                        </div>-->
                    </div>

                    <div class="t-container">
                        <div
                            class="left-box"
                            :style="tContentWidth"
                        >
                            <h3 class="main-tit">项目信息</h3>

                            <div
                                class="task-detail-panel"
                                v-if="task.task_name"
                            >
                                <h3 class="t-detail-title">项目名称</h3>
                                <div class="t-detail-bd">
                                    <p class="desc">{{ task.task_name }}</p>
                                </div>
                            </div>

                            <div
                                class="task-detail-panel"
                                v-if="task.speciality_type_value"
                            >
                                <h3 class="t-detail-title">服务类型</h3>
                                <div class="t-detail-bd">
                                    <p class="desc">{{task.speciality_type_value}}</p>
                                </div>
                            </div>

                            <div
                                class="task-detail-panel"
                                v-if="task.work_time"
                            >
                                <h3 class="t-detail-title">采购方式</h3>
                                <div class="t-detail-bd">
                                    <p
                                        class="desc"
                                        v-if="task.task_type_value"
                                    >{{ task.task_type_value }}</p>
                                    <p
                                        class="desc"
                                        v-if="task.task_type != 3"
                                    >酬金：{{task.remuneration}}元</p>
                                    <p
                                        class="desc"
                                        v-if="task.task_type == 3"
                                    >总酬金：{{task.remuneration}}元 &nbsp;&nbsp;工期：{{task.task_day}}天 &nbsp;&nbsp;每天酬金：{{task.remuneration/task.task_day}}元</p>
                                </div>
                            </div>

                            <div
                                class="task-detail-panel"
                                v-if="task.work_time"
                            >
                                <h3 class="t-detail-title">项目计划开始时间和完成时间</h3>
                                <div class="t-detail-bd">
                                    <p
                                        class="desc"
                                        v-html="task.work_time?task.work_time.replace(/,/g,' 至 '):''"
                                    >2019-09-10 至 2019-10-20</p>
                                </div>
                            </div>

                            <div class="task-detail-panel">
                                <h3 class="t-detail-title">项目成果要求</h3>
                                <div class="t-detail-bd">
                                    <p
                                        class="desc"
                                        style="word-break: break-all;word-wrap: break-word"
                                        v-html="task.introduce?task.introduce.replace(/\n/g,'<br/>'):''"
                                    ></p>
                                </div>
                            </div>

                            <div class="task-detail-panel">
                                <h3 class="t-detail-title">项目详情</h3>
                                <div class="t-detail-bd">
                                    <article class="list-item">
                                        <p
                                            class="desc"
                                            style="word-break: break-all;word-wrap: break-word"
                                            v-html="task.explain?task.explain.replace(/\n/g,'<br/>'):''"
                                        ></p>
                                    </article>
                                </div>
                            </div>

                            <div
                                class="task-detail-panel"
                                v-if="task.annex"
                            >
                                <h3 class="t-detail-title">附件</h3>
                                <div class="t-detail-bd">
                                    <p
                                        class="desc"
                                        style="margin-bottom: 10px;font-size: 12px;"
                                    >
                                        您可点击下方图标下载项目附件 或
                                        <a
                                            :href="websiteConfigs.sourceUrl + task.annex_zip"
                                            target="_blank"
                                        >打包下载</a>
                                    </p>
                                    <div class="filist-list">
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            placement="top"
                                            v-for="(itemAnnex,indexAnnex) in task.annex"
                                            :key="indexAnnex"
                                        >
                                            <div
                                                slot="content"
                                                v-if="itemAnnex"
                                            >{{itemAnnex.name}}</div>
                                            <a
                                                :href="websiteConfigs.sourceUrl+itemAnnex.url"
                                                target="_Blank"
                                                class="file-item icon-ppt"
                                                :style="'background: url('+ itemAnnex.icon +') no-repeat center '"
                                            ></a>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                            <h3 class="main-tit line-top">报名要求</h3>
                            <div
                                class="task-detail-panel"
                                v-if="task.end_time_value"
                            >
                                <h3 class="t-detail-title">报名截止时间</h3>
                                <div class="t-detail-bd">
                                    <p class="desc">{{ task.end_time_val }}</p>
                                </div>
                            </div>

                            <div
                                class="task-detail-panel"
                                v-if="task.geeks_type_value"
                            >
                                <h3 class="t-detail-title">报名身份要求</h3>
                                <div class="t-detail-bd">
                                    <p class="desc">{{task.geeks_type_value}}</p>
                                </div>
                            </div>

                            <div
                                class="task-detail-panel"
                                v-if="task.geeks_credentials"
                            >
                                <h3 class="t-detail-title">极客资质（资格）要求</h3>
                                <div class="t-detail-bd">
                                    <p
                                        class="desc"
                                        style="word-break: break-all;word-wrap: break-word"
                                        v-html="task.geeks_credentials?task.geeks_credentials.replace(/\n/g,'<br/>'):''"
                                    ></p>
                                </div>
                            </div>

                            <div
                                class="task-detail-panel"
                                v-if="task.area_limit_value"
                            >
                                <h3 class="t-detail-title">极客注册地要求</h3>
                                <div class="t-detail-bd">
                                    <p class="desc">{{task.area_limit_value}}</p>
                                </div>
                            </div>

                            <div
                                class="task-detail-panel"
                                v-if="task.work_area"
                            >
                                <h3 class="t-detail-title">工作地点要求</h3>
                                <div class="t-detail-bd">
                                    <p class="desc">{{task.work_area}}</p>
                                </div>
                            </div>

                            <div
                                class="task-detail-panel"
                                v-if="task.is_bond==1"
                            >
                                <h3 class="t-detail-title">项目需托管履约保证金</h3>
                                <div class="t-detail-bd">
                                    <p class="desc">{{task.bond}}元</p>
                                </div>
                            </div>

                            <div
                                class="task-detail-panel"
                                v-if="task.demand_annex==1"
                            >
                                <h3 class="t-detail-title">工程极客附件需求</h3>
                                <div class="t-detail-bd">
                                    <p class="desc">{{task.demand_explain}}</p>
                                </div>
                            </div>

                            <div
                                class="task-detail-panel"
                                v-if="task.finish_description"
                            >
                                <h3 class="t-detail-title">项目结束说明</h3>
                                <div class="t-detail-bd">
                                    <p
                                        class="desc"
                                        v-html="task.finish_description?task.finish_description.replace(/\n/g,'<br/>'):''"
                                    ></p>
                                </div>
                            </div>
                        </div>

                        <div
                            :class="RightBoxClass"
                            v-if="task.status>=4 && task.trade && task.trade.geek"
                        >
                            <div
                                class="item"
                                v-if="task.trade && task.trade.geek"
                            >
                                <div class="avatar">
                                    <div :style="'background: url('+ util.reImg(task.trade.geek.avatar) +') no-repeat center;background-size:100% auto; '"></div>
                                </div>

                                <div class="employer-info">
                                    <span
                                        class="user-name"
                                        :title="task.trade.geek.name"
                                    >{{task.trade.geek.name}}</span>
                                </div>
                                <span
                                    class="area"
                                    style="margin-left:10px;"
                                >{{task.employer?task.trade.geek.area_value:''}}</span>

                                <div class="other-info">
                                    <span class="des">完成项目：{{task.trade.geek.apply_numbers}} 次 / 获得酬劳：{{task.trade.geek.reward}} 元</span>

                                    <el-button
                                        class="btn"
                                        @click="geekDetail(task.trade.geek.uk)"
                                        style="margin:0px;padding:border:none;height:26px;line-height:26px;text-align:center;width:80px;font-size:12px;background:#587ddc;"
                                    >极客资料</el-button>
                                </div>
                                <img
                                    class="task_status"
                                    :src="$img.icon_zb"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="task-detail-empty"></div>

            <section
                class="ft-fixed-tip-wraper"
                :class="{'fixed': isFixedGkList }"
                id="gk-list"
            >
                <div class="container">
                    <header class="hd">
                        <div
                            class="hd-inner"
                            @click="isShowList"
                        >
                            <div class="hd-l">
                                <div
                                    class="ft-jk-double-icon"
                                    style="float: left;padding-right:10px; background: white; display: inline-block"
                                >
                                    <i class="ico-user"></i>
                                    <span class="sign-num">报名人数：{{task.enroll_count}}</span>
                                </div>
                                <div class="tip-desc">
                                    <div
                                        class="gk-avatars"
                                        v-if="task.enroll"
                                    >
                                        <el-image
                                            class="min-avatar"
                                            fit="contain"
                                            v-for="(item,index) in task.enroll"
                                            :key="index"
                                            :src="util.reImg(item.geek.avatar)"
                                        >
                                            <div
                                                slot="error"
                                                class="image-slot"
                                            >
                                                <i class="el-icon-picture-outline"></i>
                                            </div>
                                        </el-image>
                                    </div>
                                    <span
                                        class="up-gklist-tips"
                                        v-if="userInfo.isLogin == true && (userInfo.info.uk==task.uk) && task.status<5"
                                    >您可以随时展开查看报名极客情况并发送邀请</span>
                                </div>
                            </div>

                            <div class="hd-r">
                                <i
                                    class="ico-double-arr"
                                    :class="{'active':gkShwoBody}"
                                    v-if="userInfo.isLogin == true && (userInfo.info.uk==task.uk)"
                                ></i>
                                <template v-if="userInfo.isLogin==true">
                                    <!-- 项目处于报名状态 -->
                                    <template v-if="task.status==3">
                                        <!-- 已经登录用户 -->
                                        <template v-if="userInfo.info.user_type==1">
                                            <!--极客 && 未报名过-->
                                            <template v-if="userInfo.info.attestation==2 && task.is_enroll != 1">
                                                <!--项目要求极客类型和自己的相符合 || 极客类型不限制-->
                                                <template v-if="userInfo.info.attestation_type == task.geeks_type || task.geeks_type==0">
                                                    <el-button @click="goSignUp(task.tk)">立即报名</el-button>
                                                </template>
                                                <template v-else>
                                                    <el-button
                                                        style="background:#a5a5a5;border:none;"
                                                        disabled
                                                    >不满足项目参与要求</el-button>
                                                </template>
                                            </template>

                                            <!-- 极客未入驻 -->
                                            <el-button
                                                @click="userAttestation"
                                                v-if="userInfo.info.attestation!=2"
                                                style="background:#a5a5a5;border:none;"
                                                disabled
                                            >请认证极客信息后再报名</el-button>

                                            <!-- 自己已经报名过该项目 -->
                                            <el-button
                                                v-if="task.is_enroll == 1"
                                                type="info"
                                                style="background:#587ddc;"
                                                @click="todoDetail(task.tk)"
                                            >您已报名，点击查看报名详情</el-button>
                                        </template>
                                    </template>

                                    <template v-if="task.status>3 && userInfo.info.user_type==1">
                                        <el-button
                                            disabled
                                            type="info"
                                            style="background:#a5a5a5;border:none;"
                                        >报名已截止</el-button>
                                    </template>
                                </template>

                                <template v-else>
                                    <el-button
                                        v-if="task.status == 10"
                                        style="background:#a5a5a5;border:none;"
                                        disabled
                                    >项目已结束</el-button>
                                    <el-button
                                        v-else-if="task.status > 3 && task.status != 10"
                                        style="background:#a5a5a5;border:none;"
                                        disabled
                                    >报名已截止</el-button>
                                    <el-button
                                        @click="login"
                                        v-else
                                    >请登录后报名</el-button>
                                </template>
                            </div>
                        </div>

                        <div
                            class="ft-fixed-tip-bd"
                            :class="{'draw':gkShwoBody}"
                            v-if="userInfo.isLogin == true && (userInfo.info.uk==task.uk)"
                        >
                            <div class="geek-list-wraper">
                                <template v-if="task.enroll!=[]">
                                    <article
                                        class="geek-item"
                                        v-for="(item,index) in task.enroll"
                                        :key="index"
                                    >
                                        <div class="gkit-inner">
                                            <header class="gk-hd">
                                                <div class="gk-hd-l">
                                                    <el-image
                                                        class="avatar"
                                                        :src="util.reImg(item.geek.avatar)"
                                                        fit="contain"
                                                    >
                                                        <div
                                                            slot="error"
                                                            class="image-slot"
                                                        >
                                                            <i class="el-icon-picture-outline"></i>
                                                        </div>
                                                    </el-image>
                                                </div>
                                                <div class="gk-hd-r">
                                                    <div
                                                        class="info-row"
                                                        v-if="item.geek"
                                                    >
                                                        <span class="desc">完成项目：{{item.geek.task_total}}次/获得酬劳：{{item.geek.reward}}</span>
                                                        <h3 class="it-name">{{item.geek.name}}</h3>
                                                    </div>
                                                    <div class="btn-row">
                                                        <span class="desc address">{{item.geek.area_value}}</span>
                                                        <div class="btn-wraper">
                                                            <el-button
                                                                class="color-deep-gray"
                                                                @click="catUse(item.geek.uk)"
                                                            >查看资料</el-button>

                                                            <el-button
                                                                class="color-orange"
                                                                @click="send(item.geek.uk)"
                                                            >联系Ta</el-button>

                                                            <el-button
                                                                class="color-blue"
                                                                @click="invite(task.tk,item.geek.uk)"
                                                                v-if="item.status==1"
                                                            >邀请TA</el-button>

                                                            <el-button
                                                                class="color-blue"
                                                                @click="invite(task.tk,item.geek.uk)"
                                                                v-if="item.status==2"
                                                            >已邀请</el-button>

                                                            <el-button
                                                                class="color-blue"
                                                                v-if="item.status==3"
                                                            >已确认</el-button>

                                                            <el-button
                                                                class="color-blue"
                                                                @click="invite(task.tk,item.geek.uk)"
                                                                v-if="item.status==-1"
                                                            >已拒绝</el-button>

                                                            <el-button
                                                                class="color-blue"
                                                                @click="invite(task.tk,item.geek.uk)"
                                                                v-if="item.status==-2"
                                                            >确认超时</el-button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </header>
                                            <div class="gk-bd">
                                                <div class="gkbd-row">
                                                    <b
                                                        v-if="task.task_type==3"
                                                        class="price"
                                                    >极客报价：{{item.price*item.work_time}}元 &nbsp;&nbsp;工期： {{item.work_time}}天&nbsp;&nbsp;每天酬金：{{item.price}}元</b>
                                                    <b
                                                        v-else
                                                        class="price"
                                                    >极客报价：{{item.price}}元</b>
                                                    <span class="msg-tit">极客留言</span>
                                                    <a
                                                        v-if="item.annex"
                                                        :href="websiteConfigs.sourceUrl + item.annex_zip"
                                                        target="_Blank"
                                                        type="primary"
                                                        :underline="false"
                                                    >点击下载极客上传附件</a>
                                                </div>
                                                <div class="gkbd-row">
                                                    <p
                                                        class="cont"
                                                        v-text="item.content"
                                                    ></p>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </template>

                                <template v-else>
                                    <div class="nodata-wraper">
                                        <i class="el-icon-milk-tea"></i>
                                        <p class="no-tips">暂无报名极客，请耐心等待</p>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </header>
                </div>
            </section>
        </main>
        <global-footer></global-footer>
    </div>
</template>
<script>
import TopNav from "@/components/common/TopNav.vue";
import HeaderGuide from "@/components/common/HeaderGuide.vue";
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import TaskDesc from "@/components/task-detail/TaskDesc.vue";
import SignUpShow from "@/components/task-detail/SignUpShow.vue";
import SignUpList from "@/components/task-detail/SignUpList.vue";
import LoginGuide from "@/components/task-detail/LoginGuide.vue";
import EngineerMsg from "@/components/task-detail/EngineerMsg.vue";
import Hirer from "@/components/task-detail/Hirer.vue";
import HirerMsg from "@/components/task-detail/HirerMsg.vue";
import SimilarTask from "@/components/task-detail/SimilarTask.vue";
import GlobalFooter from "@/components/common/GlobalFooter.vue";
import { mapState, mapActions } from "vuex";
import Counter from "@/components/common/Counter.vue";
export default {
    name: "taskDetail",
    metaInfo() {
        return {
            title: this.article_title + " - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: "工程极客,工程技术项目,咨询人才,咨询团队,在家工作，众包平台,工程众包,造价工程,项目测绘,项目评估,工程勘测,工程设计,工程造价,工程监理,财务会计,税务服务,工程法务,技术承包,资料外包,造价工程师,装修设计"
                }, {
                    name: "description",
                    content: this.article_content
                }
            ]
        }
    },
    components: {
        TopNav, // 头部吊顶
        HeaderGuide, //头部指南
        BreadCrumb, //面包屑导航
        TaskDesc, //项目说明
        SignUpShow, //报名情况
        SignUpList, //报名人
        LoginGuide, //登录指南
        EngineerMsg, //极客登录后显示的信息
        Hirer, //雇主自己点开项目详情时只显示雇主及相关项目没有极客工程的信息
        HirerMsg, //极客工程进来时显示的雇主信息
        SimilarTask, //相似项目
        GlobalFooter, //公共底部
        Counter //倒计时
    },
    computed: {
        ...mapState(["userInfo"])
    },

    data() {
        return {
            isView: false,
            isFixedGkList: false, // 是否固定极客列表按钮栏
            gkShwoBody: false, // 是否显示极客列表
            rateVal: 4.1,
            task: "",
            signMemberList: {},
            is_subscribe: 0,
            tk: "", //项目id
            RightBoxClass: "right-box",
            enroll_end_time: {
                day: 0,
                hour: 0,
                min: 0,
                sec: 0
            },
            nodeActive: 0,
            nodeList: [],
            fixed_class: "view-box",
            enroll_list: [],
            tContentWidth: "width:100%",
            task_file: "", //招标公告文件
            article_title: "",
            article_content: ""
        };
    },

    created() {
        if (this.$route.query.tk) {
            this.tk = this.$route.query.tk;
            sessionStorage.setItem("tk", this.$route.query.tk); //缓存项目id，用于其他地方跳转回此次访问
        } else {
            this.tk = sessionStorage.getItem("tk");
        }
        if (this.$route.query.view && this.$route.query.view == 1) {
            this.isView = true;
        }
        this.loadDetailData();
        this.subscribe(); //检查是否已经关注项目

        // //改变报名列表显示状态
        // let enroll_list_status = sessionStorage.getItem('enroll_list_status');
        // if (enroll_list_status) {
        //     this.gkShwoBody = enroll_list_status;
        // }
    },

    methods: {
        //加载项目详情数据
        loadDetailData() {
            let _this = this;
            // 获取项目详情数据
            _this.post(
                "/task/index/detail",
                {
                    page: 1,
                    limit: 10,
                    tk: _this.tk
                },
                function (res) {
                    if (200 == res.code) {
                        //seo
                        _this.article_title = res.data.task_name;
                        _this.article_content = res.data.explain.replace(/<[^>].*?>/g, "").replace(/&nbsp;/g, "").substr(0, 300);//截取文章内容，去除html标签

                        _this.task = res.data; //把项目信息赋值给项目列表数据
                        _this.nodeList = _this.task.node; //加载节点数据
                        _this.nodeActive = _this.task.node_active;
                        if (_this.task.trade && _this.task.trade.geek && _this.task.trade.status >= 2) {
                            _this.tContentWidth = "width:780px";
                        }
                    }
                }
            );
        },

        //监听页面滚动事件
        winScroll(scrollTop) {
            //中标信息右侧栏控制
            if (scrollTop >= 474) {
                this.RightBoxClass = "right-box right-box-fixed";
            } else {
                this.RightBoxClass = "right-box";
            }

            if (
                scrollTop >= 38 &&
                scrollTop <= document.body.offsetHeight - 474 - 110 - 50
            ) {
                this.fixed_class = "view-box view-box-fixed";
            } else {
                this.fixed_class = "view-box";
            }

            if (
                scrollTop + this.getWindowHeight() <=
                this.getScrollHeight() - 474
            ) {
                if (this.isFixedGkList) return;
                this.isFixedGkList = true;
            } else {
                if (!this.isFixedGkList) return;
                this.isFixedGkList = false;
            }
        },

        //数字不够位数补0
        PrefixZero(num, n) {
            return (Array(n).join(0) + num).slice(-n);
        },

        //查询是否已收藏
        subscribe() {
            let _this = this;
            this.post(
                "/task/index/is_subscribe",
                {
                    tk: this.$route.query.tk
                },
                function (res) {
                    if (res.code == 200) {
                        if (res.data.is_subscribe == 1) {
                            _this.is_subscribe = 1;
                        } else {
                            _this.is_subscribe = 0;
                        }
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        },

        //收藏项目
        keep() {
            let _this = this;
            let tk = "";
            if (this.$route.query.tk == undefined) {
                tk = sessionStorage.getItem("tk");
            } else {
                tk = this.$route.query.tk;
            }

            _this.post(
                "/task/task/keep_task",
                {
                    tk: tk,
                    favor_type: _this.task.task_type,
                    favor_number: _this.task.sn,
                    favor_name: _this.task.task_name,
                    task_status: _this.task.status
                },
                function (res) {
                    if (res.code == 200) {
                        if (res.data == 1) {
                            //关注成功
                            _this.is_subscribe = 1;
                            _this.$message({
                                message: res.msg,
                                type: "success"
                            });
                        } else {
                            //取消关注
                            _this.is_subscribe = 0;
                            _this.$message({
                                message: res.msg,
                                type: "success"
                            });
                        }
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        },
        invite(tk, uk) {
            if (
                this.userInfo.info.attestation != 2 ||
                this.userInfo.attestation == ""
            ) {
                this.$message.error("认证后才可以发出邀请");
                return false;
            }

            this.$router.push("/user/task/invite?tk=" + tk + "&uk=" + uk);
        },
        send(uk) {
            sessionStorage.setItem(
                "currentPageUrl",
                document.location.pathname + document.location.search
            ); //存储当前值
            this.$router.push("/user/message/sendmsg/?uk=" + uk);
        },
        catUse(uk) {
            this.$router.push("/firm/employer?uk=" + uk);
        },
        goEdit(tk) {
            this.$router.push("/task/publish?tk=" + tk);
        },
        goPulish(tk) {
            var _this = this;
            this.post(
                "/task/task/publish",
                {
                    tk: tk
                },
                function (res) {
                    if (res.code == 200) {
                        //发布成功提示
                        _this.$message({
                            message:
                                "恭喜您的项目已经成功提交，审核完成后将在平台正式发布。",
                            type: "success"
                        });
                        _this.loadDetailData();
                    } else {
                        _this.$message({
                            message: res.msg,
                            type: "error"
                        });
                    }
                }
            );
        },
        goSignUp(tk) {
            //记录项目id
            sessionStorage.setItem("tk", tk);
            this.$router.push("/task/enroll?tk=" + tk);
        },
        isShowList() {
            this.gkShwoBody = !this.gkShwoBody;
            sessionStorage.setItem("enroll_list_status", this.gkShwoBody);
        },
        hiddenGkListEvent() {
            let jkList = document.getElementById("gk-list");

            if (jkList) {
                if (!jkList.contains(event.target)) {
                    //这句是说如果我们点击到了id为myPanel以外的区域
                    this.gkShwoBody = false;
                }
            }
        },
        //文档的总高度
        getScrollHeight() {
            var scrollHeight = 0,
                bodyScrollHeight = 0,
                documentScrollHeight = 0;
            if (document.body) {
                bodyScrollHeight = document.body.scrollHeight;
            }
            if (document.documentElement) {
                documentScrollHeight = document.documentElement.scrollHeight;
            }
            scrollHeight =
                bodyScrollHeight - documentScrollHeight > 0
                    ? bodyScrollHeight
                    : documentScrollHeight;
            return scrollHeight;
        },

        //浏览器视口的高度
        getWindowHeight() {
            var windowHeight = 0;
            if (document.compatMode == "CSS1Compat") {
                windowHeight = document.documentElement.clientHeight;
            } else {
                windowHeight = document.body.clientHeight;
            }
            return windowHeight;
        },

        //项目列表里搜索服务标签
        goTaskList(e) {
            this.$router.push("/task/list?tag=" + e);
        },

        //跳转到登录
        login() {
            //保存当前页面
            sessionStorage.setItem(
                "currentPageUrl",
                document.location.pathname + document.location.search
            );

            //跳转到登录页面
            this.$router.push("/login/index?type=login");
        },

        //报名截止倒计时结束
        endCount(e) { },

        //返回上一页
        goBack() {
            let url = sessionStorage.getItem("currentPageUrl");
            if (url) {
                this.$router.push(url);
            } else {
                this.$router.push("/user/task/list?status=0"); //返回草稿箱
            }
        },

        //跳转到极客认证页面
        userAttestation() {
            this.$router.push("/user/attest");
        },

        //报名详情
        todoDetail(tk) {
            this.$router.push("/user/task/todo?tk=" + tk);
        },

        //查看极客详情
        geekDetail(uk) {
            this.$router.push("/firm/index?uk=" + uk);
        },

        //获取项目文件
        loadFileData: function () {
            let _this = this;
            _this.post(
                "/task/task/task_file",
                { tk: _this.$route.query.tk },
                res => {
                    if (res.code == 200) {
                        _this.task_file =
                            this.websiteConfigs.sourceUrl + "/upload/pdf/web/viewer.html?file=" +
                            res.data.task;
                    } else {
                        if (res.msg) {
                            _this.$message.success(res.msg);
                        } else {
                            _this.$message.success("获取失败");
                        }
                    }
                }
            );
        },

        //查看下载招标公告文件
        view_task_file() {
            window.open(
                this.websiteConfigs.sourceUrl + "/upload/pdf/web/viewer.html?file=" +
                this.task.task_file,
                "_blank"
            );
        }
    }
};
</script>

<style lang="less">
@import "../../styles/task_employer_info.less";
@import "../../styles/taskdetail.less";
.tag_link:hover {
    color: #587ddc;
    cursor: pointer;
}
</style>



