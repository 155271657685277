<template>
    <div class="right-cont-panel icon-bg">
        <div class="engineer-panel hirer-panel">
            <h4 class="title">我的信息</h4>
            <div class="msg-cont" @click="goDetail">
                <span class="avatar">
                    <img :src="util.reImg(pageData._user.info.avatar)" alt />
                </span>
                <div class="msg-txt">
                    <h4 class="name">
                        <span>{{pageData._user.info.name}}</span>
                        <i
                            class="tag blue"
                            v-if="pageData._user.info.attestation_type && pageData._user.info.attestation_type == 1"
                        >个人入驻</i>
                        <i
                            class="tag orange"
                            v-if="pageData._user.info.attestation_type && pageData._user.info.attestation_type == 2"
                        >公司入驻</i>
                        <i
                            class="tag green"
                            v-if="pageData._user.info.attestation_type && pageData._user.info.attestation_type == 3"
                        >工作室入驻</i>
                    </h4>
                    <p class="add">{{pageData._user.info.areas?pageData._user.info.areas:''}}</p>
                </div>
            </div>
            <ul class="msg-status">
                <li>
                    <span>当前登录账号：</span>
                    <strong>{{pageData._user.base.mobile?pageData._user.base.mobile:''}}</strong>
                </li>
                <li>
                    <span>当前账号身份：</span>
                    <strong>雇主</strong>
                </li>
                <li>
                    <span>注册时间：</span>
                    <strong>{{pageData._user.info.ctime?pageData._user.info.ctime:''}}</strong>
                </li>
                <li>
                    <span>共计支付：</span>
                    <strong>￥{{pageData._user.info.total_assets?pageData._user.info.total_assets:'0'}}</strong>
                </li>
                <li>
                    <span>信用评分：</span>
                    <el-rate
                        v-model="pageData._user.info.credence"
                        disabled
                        show-score
                        text-color="#f59b15"
                        score-template="{value}"
                    ></el-rate>
                </li>
                <li>
                    <span>发布项目数量：</span>
                    <strong>{{pageData._user.info.publish_all?pageData._user.info.publish_all:'0'}}</strong>
                </li>
            </ul>
            <h5
                class="tips"
                v-if="pageData.islogin && pageData._user.info.user_id == pageData.employer_id"
            >您于{{pageData.update_time?pageData.update_time:'-'}}发布了这个项目</h5>
            <a
                href="javascript:void(0)"
                v-if="pageData.islogin && pageData._user.info.user_id == pageData.employer_id"
                class="g-btn login-btn reg-bg"
                @click="goMatters"
            >查看待办事项（{{pageData._matter_count?pageData._matter_count:'0'}}）</a>
            <a
                href="javascript:void(0)"
                v-if="pageData.islogin && pageData._user.info.user_id == pageData.employer_id"
                class="g-btn login-btn"
                @click="goDetail"
            >打开工作台</a>
            <div v-if="pageData.islogin && pageData._user.info.user_id != pageData.employer_id">
                <!-- 切换登录状态 -->
                <h5 class="tips">工程极客可报名参加项目</h5>
                <button class="g-btn login-btn" @click="go_Logingout(1)">切换登录</button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
    name: "task",
    props: {
        pageData: {}
    },
    components: {},
    data() {
        return {
            value: 3.7
        };
    },
    methods: {
        ...mapActions("userInfo", ["loginOut"]),
        goDetail() {
            this.$router.push("/user/index");
        },
        goMatters() {
            this.$router.push("/user/todos/list");
        },
        go_to(name) {
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.$router.push({ name: name });
        },
        go_Logingout(n = 0) {
            //退出登录
            this.loginOut();
            if (n == 0) {
                this.$router.push("/");
            } else {
                this.$router.push("/login/index");
            }
        }
    }
};
</script>



