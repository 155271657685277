<template>
    <div class="task-detail-panel">
        <h3 class="t-detail-title">
            项目说明
            <span>项目编号：{{ pageData.sn }}</span>
        </h3>
        <div class="task-desc">
            <p>{{ pageData.explain }}</p>
            <div class="login-tips" v-if="!pageData.islogin"  style="height:80px;">
                <router-link to="/login/index?type=login">
                    <i class="jk-icon-zhankaigengduo"></i>
                    <p>登录后查看详细说明</p>
                </router-link>
            </div>
        </div>
        <ul class="task-mag">
            <li style="">
                <em>项目附件：</em>
                <a href="javascript:void(0)" v-if="!pageData.islogin">登录后可下载</a>
                <ul class="annex-txt annex-txt-wraper" v-if="pageData.islogin">
                    <li v-for="(item,i) in pageData.annex" :key="i">
                        <a :href="websiteConfigs.sourceUrl + item.file">
                            <span class="task-tit">{{ item.name }}</span>
                            <span class="jk-iconxiazai-lan"></span>下载
                        </a>
                    </li>
                </ul>
            </li>
            <li>
                <em>补遗信息：</em>
                <i
                    v-if="pageData.annex_replenish"
                    @click="showAddendumDialog(pageData.annex_replenish)"
                >查看</i>
            </li>
            <li>
                <em>当前状态：</em>
                <strong>{{ pageData.status_name }}</strong>
            </li>
            <li>
                <em>项目酬金：</em>
                <span class="price">{{ pageData.remuneration }}(人民币)</span>
            </li>
            <li class="area">
                <em>地域条件：</em>
                <span>{{ pageData.area_limit_info }}</span>
            </li>
            <li class="identity">
                <em>身份限制：</em>
                <span>{{pageData.geeks_type_name}}</span>
            </li>
        </ul>
       
        <el-dialog title="补遗信息" :visible.sync="addendumDialog" width="60%">
            <div class="dialog-cont" v-html="addendumDialogMsg"></div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="addendumDialog=!addendumDialog">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "task",
    props: {
        pageData: {}
    },
    components: {},
    data() {
        return {
            addendumDialogMsg: "", // 补遗信息
            addendumDialog: false,
        };
    },
    methods: {
        showAddendumDialog(msg) {
            this.addendumDialogMsg = msg;
            this.addendumDialog = true;
        }
    }
};
</script>
<style lang="less">
.annex-txt-wraper {
    margin-left: 75px;
    margin-top: -30px;
}
.task-mag li .jk-iconxiazai-lan {
    color: #74a5f7;
}
</style>




