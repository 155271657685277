<template>
    <div class="right-cont-panel icon-bg">
        <div class="login-guide">
            <h4 class="title">项目报名</h4>
            <p class="tips">成为工程极客后，登录即可报名参加项目！</p>
            <router-link to="/login/index?type=login" tag="a"  class="g-btn login-btn">
                马上登录
            </router-link>
            <router-link to="/login/index?type=register" tag="a" class="g-btn reg-btn">
               还未注册？
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: "task",
    components: {},
    data() {
        return {};
    }
};
</script>



