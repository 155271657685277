<template>
    <div class="right-cont-panel icon-bg">
        <div class="engineer-panel">
            <!--这是登录状态下需要切换身份时显示-->
            <h4 class="title">我的信息</h4>
            <div class="msg-cont">
                <span class="avatar">
                    <el-image
                        :src=" pageData._user.base.avatar?pageData._user.base.avatar:$img.search_pic"
                        fit="cover"
                    ></el-image>
                </span>
                <div class="msg-txt">
                    <h4 class="name">{{ pageData._user.base.username }}</h4>
                    <p class="add">{{ pageData._user.info.areas }}</p>
                </div>
            </div>
            <ul class="msg-status">
                <li>
                    <span>当前登录账号：</span>
                    <strong>{{pageData._user.info.geek_id }}</strong>
                </li>
                <li>
                    <span>当前账号身份：</span>
                    <strong v-if="pageData._user.info.attestation_type == 0">
                        未入驻
                        <em v-if="pageData._user.info.attestation == 2">(未入驻)</em>
                    </strong>
                    <strong v-if="pageData._user.info.attestation_type == 1">
                        个人工程极客
                        <em v-if="pageData._user.info.attestation == 2">(未入驻)</em>
                    </strong>
                    <strong v-if="pageData._user.info.attestation_type == 2">
                        工作室工程极客
                        <em v-if="pageData._user.info.attestation == 2">(未入驻)</em>
                    </strong>
                    <strong v-if="pageData._user.info.attestation_type == 3">
                        单位工程极客
                        <em v-if="pageData._user.info.attestation == 2">(未入驻)</em>
                    </strong>
                </li>
            </ul>
            <div v-if="pageData._user.base.user_type == 2 && !pageData._has_enroll && pageData._user.info.attestation == 1 && pageData._user.info.attestation_type > 0 ">
                <!-- 切换登录状态 -->
                <h5 class="tips">工程极客可报名参加项目</h5>
                <router-link
                    to="/login/index?type=login"
                    tag="button"
                    class="g-btn login-btn"
                >切换登录</router-link>
            </div>
            <div v-if="!pageData._has_enroll && pageData._user.info.attestation_type == 0">
                <!-- 未入住 -->
                <h5 class="tips">入驻后即可报名项目</h5>
                <router-link
                    v-if="pageData._user.base.user_type == 1"
                    to="/user/attest"
                    tag="button"
                    class="g-btn login-btn"
                >去入驻</router-link>
                <router-link
                    v-if="pageData._user.base.user_type == 2"
                    to="/user/attest"
                    tag="button"
                    class="g-btn login-btn"
                >去入驻</router-link>
            </div>
            <div v-if="!pageData._has_enroll && pageData.status == 1 && pageData._user.info.attestation == 1 && pageData._user.info.attestation_type > 0 ">
                <!-- 受限弹窗 -->
                <!-- 剩余多少次 -->
                <h5 class="tips">本月剩余报名次数：{{pageData._user.info.apply_numbers?pageData._user.info.apply_numbers:'0'}}次</h5>
                <button
                    class="g-btn login-btn"
                    @click="goSignUp(pageData.id)"
                >立即报名</button>
                <el-dialog
                    title="区域受限  身份受限"
                    class="signupdialog"
                    :visible="false"
                    width="400px"
                    center
                >
                    <p>
                        这个项目的报名区域限制为云南省，昆明市，您的工作
                        <span>区域不符合要求</span>，请查看其它项目吧。
                    </p>
                    <p>
                        这个项目只能由
                        <span>公司工程极客</span>参与报名，您当前的入驻身份为
                        <span>个人工程极客</span>
                    </p>
                    <span
                        slot="footer"
                        class="dialog-footer"
                    >
                        <el-button @click="signupDialog = false">返 回</el-button>
                    </span>
                </el-dialog>
            </div>
            <div v-if="pageData._has_enroll">
                <!-- 已参加项目 -->
                <h5 class="tips">已经于{{pageData._enroll_time}}报名参加此项目</h5>
                <router-link
                    v-if="pageData._user.base.user_type == 1"
                    to="/user/todos/list"
                    tag="button"
                    class="g-btn login-btn reg-bg"
                >查看待办事项（1）</router-link>
                <router-link
                    v-if="pageData._user.base.user_type == 2"
                    to="/user/todos/list"
                    tag="button"
                    class="g-btn login-btn reg-bg"
                >查看待办事项（1）</router-link>
                <router-link
                    v-if="pageData._user.base.user_type == 1"
                    to="/user/index"
                    tag="button"
                    class="g-btn login-btn"
                >打开工作台</router-link>
                <router-link
                    v-if="pageData._user.base.user_type == 2"
                    to="/user/index"
                    tag="button"
                    class="g-btn login-btn"
                >打开工作台</router-link>
            </div>
            <div v-if="!pageData._has_enroll && pageData.status > 1">
                <!-- 报名已结束 -->
                <h5 class="tips">本月剩余报名次数：{{pageData._user.info.apply_numbers?pageData._user.info.apply_numbers:'0'}}次</h5>
                <button
                    class="g-btn login-btn gray-bg"
                    disabled
                >已结束报名</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "task",
    components: {},
    props: {
        pageData: {}
    },
    data() {
        return {
            signupDialog: false
        };
    },
    methods: {
        goSignUp(id) {
            this.$router.push("/task/enroll?task_id=" + id);
        }
    }
};
</script>



