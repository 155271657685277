<template>
    <div class="task-detail-panel">
        <h3 class="t-detail-title">报名情况</h3>
        <p class="sign-up-tips"><span>当前报名人数：<em>{{ pageData.enroll_nums }}人</em></span> <span>围观人次：<em>{{ pageData.reads }}次</em></span></p>
        <ul class="sign-up-show">
            <li
                v-for="(item,index) in pageData.enroll_images"
                :key="index"
            ><img :src="item?(websiteConfigs.sourceUrl + item):$img.search_pic"></li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "task",
    components: {

    },
    props: {
        pageData: {}
    },
    data() {
        return {};
    },
};
</script>



